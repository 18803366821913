import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceCall } from './service-call.service';
import { map, take } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { IMaintenanceText } from '../models/dashboard.model';
import { LocalStorageService } from './local-storage.service';

export const MAINTENANCE_TEXTS_URL = 'v1/maintenance-texts';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceTextService {
  private maintenanceTextsUrl = `${environment.apiBaseUrl}/${MAINTENANCE_TEXTS_URL}`;

  constructor(private serviceCall: ServiceCall, private localStorageService: LocalStorageService) {}

  getMaintenanceTextsCall(language: string): Observable<IMaintenanceText[]> {
    return this.serviceCall.get(`${this.maintenanceTextsUrl}?language=${language}`).pipe(map((response) => response.data));
  }

  getMaintenanceTexts(language: string) {
    this.getMaintenanceTextsCall(language)
      .pipe(take(1))
      .subscribe((maintenanceTexts) => this.localStorageService.setItem('maintenanceTexts', maintenanceTexts));
  }
}
