import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LicenseActions from './license.actions';
import { catchError, finalize, map, of, switchMap } from 'rxjs';
import { LicenseService } from '@app/shared/services/license.service';
import { Store } from '@ngrx/store';
import * as RootState from '../';
import { IOrganizationLicense, IOrganizationLicensesContainer } from '@app/shared/models/license.model';

@Injectable()
export class LicenseEffects {
  getFarmLicenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LicenseActions.GET_FARM_CONNECT_LICENSES),
      switchMap((data: { organizationId: string; language: string }) =>
        this.licenseService.getFarmLicenses(data.organizationId, data.language).pipe(
          map((farmLicenses: IOrganizationLicensesContainer) => new LicenseActions.GetFarmConnectLicensesSuccess(farmLicenses)),
          catchError((error) => of(new LicenseActions.GetFarmConnectLicensesFailure(error))),
          finalize(() => this.store.dispatch(new LicenseActions.GetFarmConnectLicensesComplete()))
        )
      )
    )
  );

  activateOrganizationLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LicenseActions.ACTIVATE_ORGANIZATION_LICENSE),
      switchMap((data: { organizationId: string; licenseId: string; language: string }) =>
        this.licenseService.activateOrganizationLicense(data.organizationId, data.licenseId, data.language).pipe(
          map((farmLicense: IOrganizationLicense) => new LicenseActions.ActivateOrganizationLicenseSuccess(farmLicense)),
          catchError((error) => of(new LicenseActions.ActivateOrganizationLicenseFailure(error))),
          finalize(() => this.store.dispatch(new LicenseActions.ActivateOrganizationLicenseComplete()))
        )
      )
    )
  );

  constructor(
    private store: Store<RootState.IState>, // tslint:disable-line:no-any
    private actions$: Actions,
    private licenseService: LicenseService
  ) {}
}
