/**
 * CoreModule
 *
 * The `CoreModule` is a singleton providing module.
 *
 * Services and shared reducers should be provided just once when the application loads, so this file
 * is imported directly into `app.module.ts`.
 *
 * This file is intended for *app-wide* singletons. Singletons that are used only by the
 * stand-alone components in `etv-utils` should be provied in their respective
 * modules.
 */

import { environment } from '@environments/environment';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import * as AnalyticsReducer from './state/analytics/analytics.reducer';
import * as ConfigurationsReducer from './state/configs/configs.reducer';
import * as ConnectionsReducer from './state/connections/connections.reducer';
import * as CouplingsReducer from './state/couplings/couplings.reducer';
import * as DashboardReducer from './state/dashboard/dashboard.reducer';
import * as MachinesOverviewReducer from './state/machine-overview/machines.reducer';
import * as MachineDetailsReducer from './state/machine-details/machine-details.reducer';
import * as FilterReducer from './state/filter/filter.reducer';
import * as OrganisationsReducer from './state/organisations/organisations.reducer';
import * as ShopsReducer from './state/shops/shops.reducer';
import * as DealerReducer from './state/dealer/dealer.reducer';
import * as AuthReducer from './state/auth/auth.reducer';
import * as FeatureToggleReducer from './state/feature-toggle/feature-toggle.reducer';
import * as UserReducer from './state/user/user.reducer';
import * as MachineDigitalDeliveryAdviceReducer from './state/machine-digital-delivery-advice/machine.digital-delivery-advice.reducer';
import * as MigrationReducer from './state/migration/migration.reducer';
import * as LicenseReduces from './state/license/license.reducer';

import { AnalyticsEffects } from './state/analytics/analytics.effects';
import { AuthEffects } from './state/auth/auth.effects';
import { ConfigurationsEffects } from './state/configs/configs.effects';
import { ConnectionsEffects } from './state/connections/connections.effects';
import { CouplingsEffects } from './state/couplings/couplings.effects';
import { DashboardEffects } from './state/dashboard/dashboard.effects';
import { DealerEffects } from './state/dealer/dealer.effects';
import { FeatureToggleEffects } from './state/feature-toggle/feature-toggle.effects';
import { FilterEffects } from './state/filter/filter.effects';
import { MachineDigitalDeliveryAdviceEffects } from './state/machine-digital-delivery-advice/machine.digital-delivery-advice.effects';
import { MachinesDetailsEffects } from './state/machine-details/machine-details.effects';
import { MachinesOverviewEffects } from './state/machine-overview/machines.effects';
import { MigrationEffects } from './state/migration/migration.effects';
import { OrganisationsEffects } from './state/organisations/organisations.effects';
import { ShopsEffects } from './state/shops/shops.effects';
import { UserEffects } from './state/user/user.effects';
import { LicenseEffects } from './state/license/license.effects';

@NgModule({
  imports: [
    CommonModule,

    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot({}),
    StoreModule.forFeature('analytics', AnalyticsReducer.reducer),
    StoreModule.forFeature('auth', AuthReducer.reducer),
    StoreModule.forFeature('configs', ConfigurationsReducer.reducer),
    StoreModule.forFeature('connections', ConnectionsReducer.reducer),
    StoreModule.forFeature('couplings', CouplingsReducer.reducer),
    StoreModule.forFeature('dashboard', DashboardReducer.reducer),
    StoreModule.forFeature('dealer', DealerReducer.reducer),
    StoreModule.forFeature('featureToggle', FeatureToggleReducer.reducer),
    StoreModule.forFeature('filter', FilterReducer.reducer),
    StoreModule.forFeature('license', LicenseReduces.reducer),
    StoreModule.forFeature('machineDetails', MachineDetailsReducer.reducer),
    StoreModule.forFeature('machineDigitalDeliveryAdvice', MachineDigitalDeliveryAdviceReducer.reducer),
    StoreModule.forFeature('machinesOverview', MachinesOverviewReducer.reducer),
    StoreModule.forFeature('migration', MigrationReducer.reducer),
    StoreModule.forFeature('organisations', OrganisationsReducer.reducer),
    StoreModule.forFeature('shops', ShopsReducer.reducer),
    StoreModule.forFeature('user', UserReducer.reducer),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot(),

    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    !environment.production ? StoreDevtoolsModule.instrument() : [],

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * This should include all Effects defined under `shared/state`!
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([AnalyticsEffects]),
    EffectsModule.forFeature([AuthEffects]),
    EffectsModule.forFeature([ConfigurationsEffects]),
    EffectsModule.forFeature([ConnectionsEffects]),
    EffectsModule.forFeature([CouplingsEffects]),
    EffectsModule.forFeature([DashboardEffects]),
    EffectsModule.forFeature([DealerEffects]),
    EffectsModule.forFeature([FeatureToggleEffects]),
    EffectsModule.forFeature([FilterEffects]),
    EffectsModule.forFeature([LicenseEffects]),
    EffectsModule.forFeature([MachineDigitalDeliveryAdviceEffects]),
    EffectsModule.forFeature([MachinesDetailsEffects]),
    EffectsModule.forFeature([MachinesOverviewEffects]),
    EffectsModule.forFeature([MigrationEffects]),
    EffectsModule.forFeature([OrganisationsEffects]),
    EffectsModule.forFeature([ShopsEffects]),
    EffectsModule.forFeature([UserEffects]),
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
