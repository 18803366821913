import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, finalize, mergeMap } from 'rxjs/operators';

import * as RootState from '@app/shared/state';
import * as MachineDigitalDeliveryAdviceActions from './machine.digital-delivery-advice.actions';

import { MachineDigitalDeliveryAdviceService } from '@app/shared/services/machine.digital-delivery-advice.service';
import { IDigitalDeliveryAdvice, IDigitalDeliveryAdviceLegalTexts, IDigitalDeliveryAdviceRequest, IUpdateDigitalDeliveryAdvice } from '@app/shared/models/machine.digital-delivery-advice.model';

@Injectable()
export class MachineDigitalDeliveryAdviceEffects {
  getDigitalDeliveryAdvice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineDigitalDeliveryAdviceActions.GET_DIGITAL_DELIVERY_ADVICE),
      map((action: MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdvice) => action.request),
      mergeMap((request: IDigitalDeliveryAdviceRequest) =>
        this.digitalDeliveryAdviceService.getDigitalDeliveryAdvice(request).pipe(
          map((digitalDeliveryAdvice: IDigitalDeliveryAdvice) =>
            new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceSuccess(request, digitalDeliveryAdvice)),
          catchError((error) => of(new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceComplete());
          })
        )
      )
    )
  );

  updateDigitalDeliveryAdvice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineDigitalDeliveryAdviceActions.UPDATE_DIGITAL_DELIVERY_ADVICE),
      mergeMap((updateDigitalDeliveryAdviceAction: MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdvice) =>
        this.digitalDeliveryAdviceService
          .updateDigitalDeliveryAdvice(
            updateDigitalDeliveryAdviceAction.request,
            updateDigitalDeliveryAdviceAction.updateDigitalDeliveryAdvice
          )
          .pipe(
            map(
              () =>
                new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceSuccess(
                  updateDigitalDeliveryAdviceAction.request,
                  updateDigitalDeliveryAdviceAction.updateDigitalDeliveryAdvice
                )
            ),
            catchError((error) => of(new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceFailure(error))),
            finalize(() => {
              this.store.dispatch(new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceComplete());
            })
          )
      )
    )
  );

  getDigitalDeliveryAdviceLegalTexts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineDigitalDeliveryAdviceActions.GET_DIGITAL_DELIVERY_ADVICE_LEGAL_TEXTS),
      map((action: MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceLegalTexts) => action.language),
      mergeMap((language: string) =>
        this.digitalDeliveryAdviceService.getDigitalDeliveryAdviceLegalTexts(language).pipe(
          map((legalTexts: IDigitalDeliveryAdviceLegalTexts) =>
            new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceLegalTextsSuccess(legalTexts)),
          catchError((error) => of(new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceLegalTextsFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceLegalTextsComplete());
          })
        )
      )
    )
  );

  constructor(
    private store: Store<RootState.IState>,
    private actions$: Actions,
    private digitalDeliveryAdviceService: MachineDigitalDeliveryAdviceService
  ) {}
}
