import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IDealerData, IDealerFilterResponse } from '../models/dealer.model';
import { ServiceCall } from './service-call.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DealerService {
  private dealerBaseUrl = `${environment.apiBaseUrl}/v2`;

  constructor(private serviceCall: ServiceCall) {}

  getDealer(dealerId: string, language: string) {
    // services wants dealer ID with leading zeros, otherwise dealer is not
    // found
    dealerId = dealerId.padStart(10, '0');
    return this.serviceCall
      .get(`${this.dealerBaseUrl}/dealers/${dealerId}`, { language })
      .pipe(
        map((response) => response.data as IDealerData)
      );
  }

  getDealerFilters() {
    return this.serviceCall
      .get(`${this.dealerBaseUrl}/dealer-filters`)
      .pipe(
        map((response) => response.data as IDealerFilterResponse)
      );
  }
}
