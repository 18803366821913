import {
  IChangeShopDealerRequest,
  IDeleteShopRequest,
  IGetOrganisationDealerWidgetContext,
  IOrganisationDealer,
  IRegisterShopRequest,
  IShop,
} from '@app/shared/models/shops.model';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as ShopsActions from './shops.actions';
import {
  switchMap,
  map,
  catchError,
  finalize,
  withLatestFrom,
  filter,
  mergeMap,
} from 'rxjs/operators';

import * as RootState from '../';
import * as OrganisationActions from '../organisations/organisations.actions';
import { ShopsService } from '@app/shared/services/shops.service';
import { IRegisteredShop, IOrganisationListItem, ShopStatus, User } from 'cde-fe-organization-registration-dialog';
import { IRegisterOrganisationResultWithDealerAndShop } from '@app/shared/models/organisation.model';
import { ServiceCallError, ServiceCallResponse } from '@app/shared/models/service-call.model';
import { UpdateDealerWidgetAfterNotFound } from '../dashboard/dashboard.actions';
import { IShopsRegistrationResponse } from 'cde-fe-organization-registration-dialog';

@Injectable()
export class ShopsEffects {
  getOrganisationShops$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopsActions.GET_ORGANISATION_SHOPS),
      withLatestFrom(this.store),
      map(([action, store]: [ShopsActions.GetOrganisationShops, RootState.IState]) => store.organisations.lastSuccess),
      filter((currentOrganisation) => currentOrganisation != null),
      switchMap((currentOrganisation) =>
        this.shopService.getOrganisationShops(currentOrganisation?.auth0Id as string).pipe(
          map((shops: IShop[]) => new ShopsActions.GetOrganisationShopsSuccess(shops)),
          catchError((error) => of(new ShopsActions.GetOrganisationShopsFailure(error))),
          finalize(() => {
            this.store.dispatch(new ShopsActions.GetOrganisationShopsComplete());
          })
        )
      )
    )
  );

  refreshAfterOrgChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.SET_CURRENT_ORGANISATION_SUCCESS, OrganisationActions.REGISTER_ORGANISATION_SHOPS_SUCCESS),
      map(() => new ShopsActions.GetOrganisationShops())
    )
  );

  getOrganisationDealer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopsActions.GET_ORGANISATION_DEALER),
      withLatestFrom(this.store),
      map(
        ([action, store]: [ShopsActions.GetOrganisationDealer, RootState.IState]) =>
          [action.payload, store.organisations.lastSuccess, action.widgetContext] as [
            string,
            Partial<IOrganisationListItem>,
            IGetOrganisationDealerWidgetContext | undefined
          ]
      ),
      mergeMap(([dealerId, currentOrganisation, widgetContext]) => {
        if (!dealerId || !currentOrganisation) {
          return of(new ShopsActions.GetOrganisationDealerComplete());
        }

        return this.shopService.getOrganisationDealer(currentOrganisation?.auth0Id as string, dealerId).pipe(
          map((organisationDealer: IOrganisationDealer) => new ShopsActions.GetOrganisationDealerSuccess(organisationDealer)),
          catchError((errorResponse: ServiceCallResponse) => {
            const error = errorResponse?.data as ServiceCallError;
            if (widgetContext != null && error?.status === 404) {
              this.store.dispatch(new UpdateDealerWidgetAfterNotFound(widgetContext));
            }

            return of(new ShopsActions.GetOrganisationDealerFailure(errorResponse));
          }),
          finalize(() => {
            this.store.dispatch(new ShopsActions.GetOrganisationDealerComplete());
          })
        );
      })
    )
  );

  getOrganisationDealers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopsActions.GET_ORGANISATION_DEALERS),
      mergeMap((action: ShopsActions.GetOrganisationDealers) => {
        const actions = action.dealerIds.map((dealerId) => new ShopsActions.GetOrganisationDealer(dealerId, action.widgetContext));
        return actions;
      })
    )
  );

  unregisterShop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopsActions.UNREGISTER_ORGANISATION_SHOP),
      withLatestFrom(this.store),
      map(
        ([action, store]: [ShopsActions.UnregisterOrganisationShop, RootState.IState]) =>
          [action.deleteShop, store.organisations.lastSuccess, action.newDealer] as [
            IRegisteredShop,
            Partial<IOrganisationListItem>,
            string | undefined
          ]
      ),
      filter(([deleteShop, org, newDealer]) => org != null),
      mergeMap(([deleteShop, org, newDealer]: [IRegisteredShop, Partial<IOrganisationListItem>, string | undefined]) =>
        this.shopService.unregisterShop({ shopId: deleteShop.id, organisationId: org.auth0Id as string }).pipe(
          map((unregisteredShop) => new ShopsActions.UnregisterOrganisationShopSuccess(deleteShop, newDealer)),
          catchError((error) => of(new ShopsActions.UnregisterOrganisationShopFailure(error))),
          finalize(() => this.store.dispatch(new ShopsActions.UnregisterOrganisationShopComplete()))
        )
      )
    )
  );

  changeShopDealer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopsActions.CHANGE_SHOP_DEALER),
      map((action: ShopsActions.ChangeShopDealer) => action.changeDealerRequest),
      map(
        (changeDealer: IChangeShopDealerRequest) => new ShopsActions.UnregisterOrganisationShop(changeDealer.shop, changeDealer.newDealerId)
      )
    )
  );

  // retrieve information about the preferred dealer after registering an organisation
  registerOrganisationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.REGISTER_ORGANISATION_SUCCESS),
      map((action: OrganisationActions.RegisterOrganisationSuccess) => action.payload),
      map(
        (registrationData: IRegisterOrganisationResultWithDealerAndShop) =>
          new ShopsActions.GetOrganisationDealer(registrationData.dealer.id)
      )
    )
  );

  getConnectedDealers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopsActions.GET_CONNECTED_DEALERS),
      withLatestFrom(this.store),
      map(([action, store]: [ShopsActions.GetConnectedDealers, RootState.IState]) => store.organisations.lastSuccess),
      filter((currentOrganisation) => currentOrganisation != null),
      mergeMap((currentOrganisation) =>
        this.shopService.getConnectedDealers((currentOrganisation as IOrganisationListItem).auth0Id).pipe(
          map((dealers) => new ShopsActions.GetConnectedDealersSuccess(dealers)),
          catchError((error) => of(new ShopsActions.GetConnectedDealersFailure(error))),
          finalize(() => this.store.dispatch(new ShopsActions.GetConnectedDealersComplete()))
        )
      )
    )
  );

  unregisterOrganisationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopsActions.UNREGISTER_ORGANISATION_SHOP_SUCCESS),
      filter((action: ShopsActions.UnregisterOrganisationShopSuccess) => (action.newDealer?.length ?? 0) > 0),
      withLatestFrom(this.store),
      map(
        ([action, store]: [ShopsActions.UnregisterOrganisationShopSuccess, RootState.IState]) =>
          [action, store.auth.user] as [ShopsActions.UnregisterOrganisationShopSuccess, User | null]
      ),
      map(
        ([action, user]: [ShopsActions.UnregisterOrganisationShopSuccess, User | null]) =>
          [action.deleteShop, action.newDealer, user?.user_metadata.country] as [IRegisteredShop, string, string]
      ),
      map(
        ([deleteShop, newDealerId, country]: [IRegisteredShop, string, string]) =>
          new OrganisationActions.RegisterOrganisationShops({
            request: {
              shops: [
                {
                  shopId: deleteShop.shopId,
                  dealerId: newDealerId,
                  status: ShopStatus.REQUESTED,
                  translationKey: deleteShop.translationKey,
                  countries: [],
                  requiresDealerRegistration: deleteShop.requiresDealerRegistration ?? false,
                  requiresFullRegistration: deleteShop.requiresFullRegistration ?? false,
                },
              ],
            },
            country,
          }, true)
      )
    )
  );

  registerShopSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.REGISTER_ORGANISATION_SHOPS_SUCCESS),
      filter((action: OrganisationActions.RegisterOrganisationShopsSuccess) => !!action.isChangeRequest),
      map((action: OrganisationActions.RegisterOrganisationShopsSuccess) => action.payload),
      map(
        (response: IShopsRegistrationResponse) =>
          new ShopsActions.ChangeShopDealerComplete()
      )
    )
  );

  registerShop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopsActions.REGISTER_ORGANISATION_SHOP),
      withLatestFrom(this.store),
      map(
        ([action, store]: [ShopsActions.RegisterOrganisationShop, RootState.IState]) =>
          [action.registerShop, store.organisations.lastSuccess] as [IRegisterShopRequest, Partial<IOrganisationListItem>]
      ),
      filter(([registerShop, org]) => org != null),
      mergeMap(([registerShop, org]: [IRegisterShopRequest, Partial<IOrganisationListItem>]) => {
        const request: IRegisterShopRequest = {
          ...registerShop,
          organisationId: org.auth0Id,
        };

        return this.shopService.registerShop(request).pipe(
          map((response) => new ShopsActions.RegisterOrganisationShopSuccess(response)),
          catchError((error) => of(new ShopsActions.RegisterOrganisationShopFailure(error))),
          finalize(() => this.store.dispatch(new ShopsActions.RegisterOrganisationShopComplete()))
        );
      })
    )
  );

  constructor(
    private store: Store<RootState.IState>, // tslint:disable-line:no-any
    private actions$: Actions,
    private shopService: ShopsService
  ) {}
}
