import { inject } from '@angular/core';
import { UrlTree, Route, UrlSegment, Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import * as RootState from '../state';

import { getFeatureToggles, getFeatureTogglesInitialized } from '../state/feature-toggle';
import { Features } from '../models/feature-toggle.model';

export function canLoadMigrationRouteGuard(
  route: Route,
  segments: UrlSegment[]
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  const router = inject(Router);
  const store = inject(Store<RootState.IState>);

  const hasFeatureToggle$ = combineLatest([store.select(getFeatureToggles), store.select(getFeatureTogglesInitialized)]).pipe(
    filter(([featureToggles, featureTogglesInitialized]) => featureTogglesInitialized),
    map(([featureToggles, featureTogglesInitialized]) =>
        (featureToggles?.length === 0 || featureToggles?.some((toggle) => toggle.id === Features.MIGRATION)) ?? false
    ),
    tap((hasFeatureToggle) => {
      if (!hasFeatureToggle) {
        void router.navigate(['dashboard']);
      }
    })
  );

  return hasFeatureToggle$;
}
